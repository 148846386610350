<template>
	<div v-if="editor">
				<v-row dense>
					<v-col cols="12">
						<div class="float-left">
							<v-btn class="ma-2" color="primary" :outlined="!editor.isActive('bold')" @click="editor.chain().focus().toggleBold().run()">
								<v-icon>{{icons.mdiFormatBold}}</v-icon>
							</v-btn>
							<v-btn class="ma-2" color="primary" :outlined="!editor.isActive('italic')" @click="editor.chain().focus().toggleItalic().run()">
								<v-icon>{{icons.mdiFormatItalic}}</v-icon>
							</v-btn>
							<v-btn class="ma-2" color="primary" :outlined="!editor.isActive('strike')" @click="editor.chain().focus().toggleStrike().run()">
								<v-icon>{{icons.mdiFormatStrikethrough}}</v-icon>
							</v-btn>
							<v-btn class="ma-2" color="primary" :outlined="!editor.isActive('code')" @click="editor.chain().focus().toggleCode().run()">
								<v-icon>{{icons.mdiCodeBrackets}}</v-icon>
							</v-btn>
							<v-btn v-if="links.length>0" class="ma-2" :outlined="links.length<1" color="primary" @click="linksDialog=true">
								<v-icon>{{icons.mdiCreation}}</v-icon>
							</v-btn>
							<v-btn class="ma-2" :outlined="!editor.isActive('link')" color="primary" @click="setCasualLink()">
								<v-icon>{{icons.mdiLink}}</v-icon>
							</v-btn>
              <v-btn v-if="type==='ad-post'" class="ma-2" :outlined="!editor.isActive('link')" color="primary" @click="setAdLink()">
                <v-icon>{{icons.mdiLink}}</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col>
						<div class="float-left">
							<v-btn v-if="type==='post'" class="ma-2" color="info" :outlined="locked" :disabled="locked" @click="reload()">
								<v-icon>{{icons.mdiAutorenew}}</v-icon>
							</v-btn>
							<v-btn v-if="type==='post'" class="ma-2" :color="colors.red.base" :outlined="!locked" @click="locked = !locked">
								<v-icon :color="locked ? colors.shades.white : 'primary'">{{icons.mdiLockOutline}}</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			<v-spacer></v-spacer>
			<v-card-text>
        <div id="editor_element" style="border: 1px solid black;">
          <editor-content :editor="editor" />
        </div>

			</v-card-text>
			<v-card-text>
				<div v-if="editor" class="character-count">
					{{ editor.storage.characterCount.characters() }}/1024 символов
				</div>
			</v-card-text>
		<v-dialog v-model="linksDialog" width="600">
			<v-container>
				<v-card>
					<v-card-text>
						<v-row v-for="(link, i) in links" :key="i">
							<v-col cols="10">
								<v-text-field v-model="link.title" outlined label="Текст"></v-text-field>
							</v-col>
							<v-col cols="2">
								<v-btn color="blue" class="mt-2" @click="addLink(link.index)">+</v-btn>
							</v-col>
						</v-row>
					</v-card-text>

				</v-card>

			</v-container>
		</v-dialog>
	</div>

</template>

<script>
import {mdiFormatBold, mdiFormatItalic, mdiFormatStrikethrough, mdiCodeBrackets, mdiAutorenew, mdiLockOutline, mdiCreation, mdiLink} from '@mdi/js'
import _ from 'lodash'
import { Editor, EditorContent } from '@tiptap/vue-2'
// import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import Link from '@tiptap/extension-link'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import History from '@tiptap/extension-history'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import HardBreak from '@tiptap/extension-hard-break'
import Code from '@tiptap/extension-code'
import colors from 'vuetify/lib/util/colors'

export default {
  components: { EditorContent},
  props: {
    modelValue: {type: String, default: ''},
    links:{ type: Array, default() {return []} },
    type:{type: String, default: 'post'} //post - для постов с товаркой или акцией, т.е. в нашем канале. ad-post - для рекламных постов
  },
  data() {
    return {
      reminded: false,
      icons: {mdiFormatBold, mdiFormatItalic, mdiFormatStrikethrough, mdiCodeBrackets, mdiAutorenew, mdiLockOutline, mdiCreation, mdiLink},
      colors,
      toggle_multiple: null,
      editor: null,
      locked: false,
      linksDialog: false,
      lengthDialog: false,
    }
  },

  computed:{
    textLength(){
      if (this.editor){
        return this.editor.storage.characterCount.characters()
      }
      return 0
    }
  },

  watch: {
    textLength(v){
      this.$emit('update:textLength', v)
      if (v>1024 && !this.reminded){
        this.reminded = true
        this.$toast('Пост вышел за рамки 1024 символов. Просто напоминаю.')
      }
    },
    modelValue(value) {
      console.log('modelValue changed: ', value)
      //editor возвращает текст в тегах <p>, а modelValue - без них. если они одинаковые, то изменения произошли вне редактора, и надо обновить его содержимое
      if ('<p>'+value+'</p>' !== this.editor.getHTML()){
        console.log('... and we need to update editor!')
        console.log(this.editor.getHTML())

        this.editor.commands.setContent(value, false)
      }
      this.$store.dispatch('setPostText', value) //это по сути просто резервный способ получить текст из редактора
    },
    locked(v){
      this.$store.dispatch('canUpdateResultText', !v)
    }
  },

  mounted() {
    this.editor = new Editor({
      useBuiltInExtensions: false,
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline']
        ]
      },
      theme: 'snow',
      formats: ['bold', 'underline', 'italic'],
      extensions: [
        Document,
        Bold,
        Strike,
        Italic,
        Text,
        Paragraph,
        HardBreak.extend({
          addKeyboardShortcuts() {
            return {
              // ёбаный типтап не хочет работать без параграфа, а он нам нахуй не нужон. поэтому меняем действие: теперь на энтер будет делаться новая строка.
              // плюс при каждом обновлении текста в редакторе теги <p> будут меняться на <br> (clearHtml())
              Enter: () => this.editor.commands.setHardBreak()
            }
          }
        }),
        // StarterKit,
        CharacterCount,
        Code,
        Link.configure({
          openOnClick: false,
          HTMLAttributes: { target: undefined, rel: undefined }
        }),
        History,
      ],
      content: this.modelValue,
      onUpdate: () => {
        this.$emit('update:modelValue', this.clearHtml(this.editor.getHTML()))
        this.$store.dispatch('setPostText', this.clearHtml(this.editor.getHTML())) //это по сути просто резервный способ получить текст из редактора
        // если содержимое редактора НЕ равно содержимому modelValue - значит, вручную внесли какие-то изменения в тексте, и надо заблокировать автоматическое редактирование
        if (this.editor.getHTML() !== this.modelValue){
          this.locked = true
        }
      },
    })
  },
  methods:{
    clearHtml(v){
      v = _.trim(v)
      v = v.replace(/<p[^>]*>/g, '<br>')
      v = v.replace(/<\/?p[^>]*>/g, '<br>')
      v = v.replace(/&nbsp;/g, '')
      if (v.length > 3){
        // заодно удалим первые и последние <br>
        if (v.substring(0, 4) === '<br>') {
          v = v.replace(/<br[^>]*>/, '')
        }
        if (v.substring(v.length - 4) === '<br>'){
          v = v.substring(0, v.length-4)
        }
      }
      return v
    },
    reload(){
      this.$store.dispatch('needUpdateResultPost', true)
      this.setText(this.modelValue)
    },
    addLink(i){
      this.editor.commands.setLink({ href: '{{URL_' + i + '}}', rel: undefined, target: undefined})
      this.linksDialog = false
    },
    setCasualLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)
      // cancelled
      if (url === null) {
        return
      }
      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()
        return
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    setAdLink() {
      if (this.editor.isActive('link')){
        console.log('active link!')
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()
      }else{
        this.editor.commands.setLink({ href: '{{ADLINK}}', rel: undefined, target: undefined})
      }
    },
    setText(text, lock = false, force = false){
      // if (!this.locked || force){
        console.log('устанавливаем новый текст: ', text)
        this.editor.commands.setContent(text, false)
      // }
      if (lock){
        console.log('... и блокируем изменения!')
        this.locked = true
      }
    }
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
<style scoped>
</style>
